<template>
  <ZMainPage type="card">
    <template #footer>
      <el-button type="primary" @click="$router.back()">返回</el-button>
    </template>
    <div class="z-box-bg mb-2">
      <LineWrapper class="list" list>
        <LineItem v-for="(item, index) in list" :key="index" :label="item.label" :value="item.value"></LineItem>
      </LineWrapper>
    </div>
    <div class="info-wrapper">
      <div class="info-content flex-1">
        <div class="z-box-bg p-2">
          <div class="info-inner-title"><span class="txt">辅导类型</span></div>
          <div class="info-inner-content tags">
            <div class="info-tips-tag" v-for="item in info.coach_type" :key="item">{{ item }}</div>
          </div>
          <div class="info-inner-title"><span class="txt">辅导内容</span></div>
          <div class="info-inner-content content">{{ info.coach_content }}</div>
        </div>
      </div>
    </div>
  </ZMainPage>
</template>

<script>
import LineItem from "@/components/common/LineItem";
import LineWrapper from "@/components/common/LineWrapper";
import http from '@/api'
export default {
	_config:{'route':{'path':'detail','meta':{'title':'详情'}}},
  components: { LineItem, LineWrapper },
  data() {
    return {
      list: [],
      info: {
        coach_type: [],
        coach_content: ''
      }
    };
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      http({ url: '/teacher/moral-educationController/record-details', pre: 'api2'}, { id: this.$route.query.id }).then(res => {
        const { coach_type, coach_content } = res
        this.info = { coach_type, coach_content }
        this.list = [
          { label: '学生姓名', value: res.student_name },
          { label: '学号', value: res.student_no },
          { label: '校区', value: res.school_name },
          { label: '年级', value: res.grade_name },
          { label: '行政班', value: res.class_name },
          { label: '辅导教师', value: res.org_teacher_name || '-' },
          { label: '起始日期', value: res.day_describe },
          { label: '辅导完成时间', value: res.coach_time || '-' },
        ]
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.info {
  &-wrapper {
    display: flex;
    justify-content: space-between;
  }
  &-content {
    font-size: 16rem;
    display: flex;
    flex-direction: column;
    .info-box {
      flex: 1;
    }
  }
  &-inner-title {
    position: relative;
    margin: 0 -24rem 24rem -24rem;
    padding: 0 24rem;
    color: #1E3AA2;
    font-size: 22rem;
    border-bottom: solid 1px #E5E5E5;
    .txt {
      position: relative;
      display: inline-block;
      padding-bottom: 14rem;
      &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        width: 100%;
        height: 2px;
        background-color: #1E3AA2;
      }
    }
  }
  &-inner-content {
    width: 50%;
    padding: 20rem;
    margin-bottom: 30rem;
    font-size: 14rem;
    color: #3C3C3C;
    background-color: #F9FAFD;
    border-radius: 6rem;
    &.tags {
      display: flex;
      flex-wrap: wrap;
      gap: 20rem;
    }
    &.content {
      min-height: 160rem;
    }
  }
  &-tips-tag {
    display: inline-block;
    padding: 10rem 14rem;
    font-size: 16rem;
    color: #1C4EFD;
    background-color: #fff;
    border: solid 1px #0256FF;
    border-radius: 4rem;
  }
}
</style>
